import "../sass/main.scss";

//import "slick-carousel/slick/slick";
import "./jquery.vnm.percentWithinViewport.min";

import "./script-modal";
import "./_sprite";
import "./script-components";

let pattern_row_before = '<svg preserveAspectRatio="none" viewBox="0 0 1920 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 19.493C0 19.493 366.918 -24.3662 889.685 19.493C1412.45 63.3522 1479.33 0.250155 1920 9.74267V37H0V19.493Z" fill="currentColor"/></svg>';
let pattern_row_after = '<svg preserveAspectRatio="none" viewBox="0 0 1920 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1920 17.507C1920 17.507 1553.08 61.3663 1030.32 17.5071C507.548 -26.3521 440.675 36.75 -3.05272e-05 27.2575L-3.29101e-05 0.000166905L1920 -9.46445e-07L1920 17.507Z" fill="currentColor"/></svg>';
jQuery(document).ajaxComplete(function () {
    initialiseMain();
});
jQuery(window).resize(function () {
    stickToSides();
    stickyTop();
});

jQuery(document).ready(function () {
    initialiseMain();
    stickToSides();
    addPattern();
    initialiseParallax();
    initialiseDelayTransitions();
    //delayTransitions();
    stickyTop();
    // initBackToTop();
    addNoMarginBottomToSpecialRows();
    addClassToContactPage();
});

jQuery(window).scroll(function() {
    //runParallax();
    //delayTransitions();
    stickyTop();
});

function addClassToContactPage() {
    if (jQuery('#form_contact-form').length > 0) {
        jQuery('body').addClass('has_contact-form');
    }
}

function addNoMarginBottomToSpecialRows() {
    const rows = document.querySelectorAll('.vc_row');

    rows.forEach(row => {
        const specialTextElements = row.querySelectorAll('.text-special');

        if(specialTextElements.length > 0 && specialTextElements.length === row.children.length) {
            row.classList.add('no-padding-bottom');
        }
    });
}



function initBackToTop(){
    let button = jQuery('<span class="back-to-top use-svg ise-icon use-icon-back-to-top"></span>').appendTo('footer');
    button.on('click',function(){
        jQuery("html, body").animate({ scrollTop: 0 }, "fast");
        return false;
    });
}

function initialiseMain() {
    jQuery('.js-menu-burger').click(function () {
        jQuery(this).toggleClass('is-active');
        jQuery('html').toggleClass('is-active-modal is-active-menu-burger');
    });

    /*
     * Pouvoir cliquer sur l'ensemble d'une carte
     */
    jQuery('.js-card').click(function () {
        window.location.href = jQuery(this).find('.js-button').attr('href');
    }).hover(function () {
        jQuery(this).find('.js-button').toggleClass('is-active');
    });

    jQuery('.card').hover(function () {
        jQuery(this).find('.button, .button-link, .js-button').toggleClass('is-hover');
    });

    // listes déroulantes :
    jQuery('.toggle-block').each(function () {
        let $element= jQuery(this);
        let $btn = jQuery(this).find('> .toggle-block__btn');

        $btn.click(function(){
            $element.toggleClass('is-active');
        });
    });

    jQuery('.nr-js-filters-mobile').click(function(){
        jQuery(this).parent().toggleClass('active');
    });
}

function inputChange() {
    jQuery('input[type="radio"]').change(function () {
        jQuery(this).parent().toggleClass('is-active');
    });
}


function addPattern() {
    jQuery('.pattern-before:not(.pattern-before-is-loaded)').each(function () {
        let pattern_use = pattern_row_before;

        let elt_class = jQuery(this).attr('class').split(' ');
        let pattern_color = 'color-primary';
        let pattern_bg_color = '';
        for (let i = 0; i < elt_class.length; i++) {
            if (elt_class[i].slice(0, 20) === 'pattern-before-color') {
                pattern_color = elt_class[i].slice(15);
            }

            if (elt_class[i].slice(0, 23) === 'pattern-before-bg-color') {
                pattern_bg_color = elt_class[i].slice(15);
            }
        }

        let block = jQuery('<div class="block-pattern block-pattern-before ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').prependTo(jQuery(this));
        let height = block.height();

        jQuery(this)
            .css('overflow', 'visible')
            .css('position', 'relative');

        if (!jQuery(this).hasClass('pattern-before-no-margin'))
            jQuery(this).css('margin-top', height);

        jQuery(this).addClass('pattern-before-is-loaded');

        // patternBgColor(jQuery(this), block);
    });

    jQuery('.pattern-after:not(.pattern-after-is-loaded)').each(function () {
        let pattern_use = pattern_row_after;

        let elt_class = jQuery(this).attr('class').split(' ');
        let pattern_color = 'color-primary';
        let pattern_bg_color = '';
        for (let i = 0; i < elt_class.length; i++) {
            if (elt_class[i].slice(0, 19) === 'pattern-after-color') {
                pattern_color = elt_class[i].slice(14);
            }

            if (elt_class[i].slice(0, 22) === 'pattern-after-bg-color') {
                pattern_bg_color = elt_class[i].slice(14);
            }
        }

        let block = jQuery('<div class="block-pattern block-pattern-after ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').appendTo(jQuery(this));
        let height = block.height();

        jQuery(this)
            .css('overflow', 'visible')
            .css('position', 'relative');

        if (!jQuery(this).hasClass('pattern-after-no-margin'))
            jQuery(this).css('margin-bottom', height);

        jQuery(this).addClass('pattern-after-is-loaded');

        //patternBgColor(jQuery(this), block);
    });

}

function patternBgColor(parent, block) {
    if (jQuery(parent).hasClass('bg-color-primary')) {
        block.addClass('block-pattern-bg-color-primary');
    } else if (jQuery(parent).hasClass('bg-color-secondary')) {
        block.addClass('block-pattern-bg-color-secondary');
    } else if (jQuery(parent).hasClass('bg-color-tertiary')) {
        block.addClass('block-pattern-bg-color-tertiary');
    } else if (jQuery(parent).hasClass('bg-color-white')) {
        block.addClass('block-pattern-bg-color-white');
    } else if (jQuery(parent).hasClass('bg-color-black')) {
        block.addClass('block-pattern-bg-color-black');
    } else if (jQuery(parent).hasClass('bg-color-neutral-1')) {
        block.addClass('block-pattern-bg-color-neutral-1');
    }
}

function stickToSides() {
    let $minWidth = 0;
    let $maxWidth = 2600;
    let $window = jQuery(window);

    jQuery('.stickToLeft').each(function () {
        let $element = jQuery(this);
        let $offset = $element.offset().left;
        let $left = $offset;
        let $newWidth = $width + $left;
        let $css = '';

        if ($window.width() > $minWidth && $window.width() < $maxWidth) {
            $css = 'margin-left:-'+ $offset +'px;width:'+ $newWidth +'px';

            if($element.is('.stickToLeftKeepContentWidth')){
                $css = $css + ';padding-left:'+$offset+'px !important';
            }

            $element.css('cssText', $css);

        }
        else {
            $element.css('margin-left', '');
            $element.css('padding-left', '');
        }
    });

    jQuery('.stickToRight').each(function () {
        let $element = jQuery(this);
        let $offset = $element.offset().left;
        let $width = $element.outerWidth();
        let $right = $window.width() - ($offset + $width);
        let $newWidth = $width + $right;
        let $css = '';

        if ($window.width() > $minWidth && $window.width() < $maxWidth) {
            $css = 'margin-right:-'+ $right +'px;width:'+ $newWidth +'px';

            if($element.is('.stickToRightKeepContentWidth')){
                $css = $css + ';padding-right:'+$right+'px !important';
            }

            $element.css('cssText', $css);
        }
        else{
            $element.css('cssText', '');
        }
    });
}

function initialiseDelayTransitions(){
    jQuery('.js-delay-transition').each(function(){
        let $element = jQuery(this);
        $element.addClass('js-delay-transition-init');
    });
}
function delayTransitions(){
    jQuery('.js-delay-transition-init').percentWithinViewport();
    jQuery('.js-delay-transition-init').each(function(){
        let $element = jQuery(this);
        let $percent = $element.attr('data-percent-viewport');
        if($percent > 40){
            $element.addClass('js-delay-transition-active');
        }
        else if( $element.is('.js-delay-transition-can-reverse') ){
            $element.removeClass('js-delay-transition-active');
        }
    });
}

function initialiseParallax(){
    jQuery('.parallax').each(function(){
        let $element = jQuery(this);
        $element.parent().css('overflow','hidden');
    });
}
function runParallax(){
    let minWidth = 768;
    let $window = jQuery(window);

    if ($window.width() < minWidth) {
        jQuery('.parallax').css({
            '-webkit-transform': '',
            '-moz-transform': '',
            '-ms-transform': '',
            '-o-transform': '',
            'transform': ''
        });
        return;
    }

    jQuery('.parallax').percentWithinViewport();
    jQuery('.parallax').each(function(){
        let $element = jQuery(this);
        let translateX = '';
        let translateY = '';
        let $percent = $element.attr('data-percent-viewport');

        if($element.is('.parallax-x')){
            let $slowX = 9;
            let $reverseX = '';
            let $translateXVal = '';

            if($element.is('.parallax-x-slow-1')) $slowX = 3;
            else if($element.is('.parallax-x-slow-2')) $slowX = 6;
            else if($element.is('.parallax-x-slow-3')) $slowX = 9;
            else if($element.is('.parallax-x-slow-4')) $slowX = 12;
            else if($element.is('.parallax-x-slow-5')) $slowX = 15;

            if($element.is('.parallax-x-reverse')) $reverseX = '-';

            if( ($element.offset().top) > jQuery(window).scrollTop() ) {
                $translateXVal = (100 - $percent) / $slowX;
            }
            else{
                $translateXVal = 0;
            }

            translateX = 'translateX(' + $reverseX + $translateXVal + '%)';
        }

        if($element.is('.parallax-y')){
            let $slowY = 9;
            let $reverseY = '';
            let $translateYVal = '';

            if($element.is('.parallax-y-slow-1')) $slowY = 3;
            else if($element.is('.parallax-y-slow-2')) $slowY = 6;
            else if($element.is('.parallax-y-slow-3')) $slowY = 9;
            else if($element.is('.parallax-y-slow-4')) $slowY = 12;
            else if($element.is('.parallax-y-slow-5')) $slowY = 15;

            if($element.is('.parallax-y-reverse')) $reverseY = '-';

            if( ($element.offset().top) > jQuery(window).scrollTop() ) {
                $translateYVal = (100 - $percent) / $slowY;
            }
            else{
                $translateYVal = 0;
            }

            translateY = 'translateY(' + $reverseY + $translateYVal + '%)';
        }

        if( translateX || translateY ){
            $element.css({
                '-webkit-transform': translateX +' '+translateY ,
                '-moz-transform': translateX +' '+translateY,
                '-ms-transform': translateX +' '+translateY,
                '-o-transform': translateX +' '+translateY,
                'transform': translateX +' '+translateY
            });
        }
    });
}

function stickyTop(){
    let $stickyContainer = jQuery('.sticky-header');
    let $window = jQuery(window);
    let $body = jQuery('body');
    let $docViewTop = $window.scrollTop();
    let $padding = 0;


    /*if( $window.width() < 992 ){
        $body.css('padding-top','');
        $body.removeClass('js-sticky-on');
        $stickyContainer.removeClass('sticky--scrolled');
        return false;
    }*/

    if( $stickyContainer.length > 0 )
    {
        if( !$body.is('.js-sticky-active'))
        {
            if(isInvisibleInWindow($stickyContainer, 100)){
                $stickyContainer.addClass('sticky--scrolled');
                $body.addClass('js-sticky-active');
                $stickyContainer.attr('data-initial-height', $stickyContainer.outerHeight() );

                if( $body.is('.header__color-dark') ){
                    $body.addClass('js-header-switch-color');
                }
            }
        }

        if( $docViewTop > 0 && $body.is('.js-sticky-active') ){
            $body.addClass('js-sticky-on');

            //if( !$body.is('.header_template_template1') ){
            $padding = $stickyContainer.attr('data-initial-height');
            $body.css('padding-top',$padding);
            //}

            if( $body.is('.js-header-switch-color') ){
                $body.removeClass('header__color-dark');
            }
        }
        else if( $body.is('.js-sticky-active') ){
            $body.removeClass('js-sticky-on');

            if( $body.is('.js-header-switch-color') ){
                $body.addClass('header__color-dark');
            }

            //if( !$body.is('.header_template_template1') ){
            $padding = $stickyContainer.attr('data-initial-height');
            $body.css('padding-top',$padding + 'px');
            //}
        }
    }
}

function isInvisibleInWindow(elem,offset=0){
    let $elem = jQuery(elem);
    let $window = jQuery(window);

    let docViewTop = $window.scrollTop();
    let docViewBottom = docViewTop + $window.height();

    let elemTop = $elem.offset().top;
    let elemBottom = elemTop + $elem.height();

    return ((elemTop >= docViewBottom) || ((elemBottom + offset) <= docViewTop));
}