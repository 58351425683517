/**
 * Gestion de fichier sprite SVG
 * Et implémentation de SVG via classes CSS
 */

// Import des svg dans le lepreuchaun (sprite)
requireAll(require.context('../icon/', true, /\.svg$/));

jQuery(document).ready(function () {

    /*
     * Pattern H2
     */
    let h2 = jQuery('h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6), .h2');
    h2.each(function () {
        let element = jQuery(this);
        let content = element.html();
        content = content.split(' ');

        content[content.length - 1] = '<span class="last-word">' + content[content.length - 1] + '</span>';
        jQuery(this).html(content.join(' '));
    });
    /* END */

    let buttons = jQuery('.frm_button_submit,.button:not(.use-svg)').addClass('use-svg use-icon use-icon-arrow-button svg-margin flex-row-reverse');
    let buttons_download = jQuery('.download-link:not(.use-svg)').addClass('use-svg use-icon use-icon-download svg-margin');
    let links = jQuery('.button-link').addClass('use-svg use-icon use-icon-arrow-link svg-margin flex-row-reverse');
    let button_outline = jQuery('.button-outline').addClass('use-svg use-icon use-icon-arrow-button svg-margin flex-row-reverse');
    let phones = jQuery('.button-phone').addClass('use-svg use-icon use-icon-phone svg-margin');
    let booking = jQuery('.button-booking').addClass('use-svg use-icon use-icon-calendar svg-margin');

    let submenus = jQuery('.has-mega-menu > a').addClass('use-svg use-icon use-icon-has-mega-menu svg-margin flex-row-reverse');

    let $toggleBlocks = jQuery('.toggle-block');
    $toggleBlocks.find('> .toggle-block__btn').addClass('use-svg use-icon use-icon-chevron-down on-right flex-row-reverse');

    setSvg();

    jQuery('.slick').on('init', function () {
        setSvg();
    });
});

jQuery(document).ajaxComplete(function () {
    setSvg();
});

jQuery(document).on('frmPageChanged', function (event, form, response) {
    setSvg();
});

// Autoloader JS
function requireAll(r) {
    r.keys().forEach(r);
}

// Ajout de SVG par détection de classes CSS : use-icon use-icon-[NAME]
function setSvg() {
    let use_svg = jQuery('.use-svg:not(.is-loaded)');
    if (use_svg.length) {
        use_svg.each(function () {
            let elt = jQuery(this);
            let classList = jQuery(this).attr('class').split(/\s+/);
            jQuery.each(classList, function (index, item) {
                if (item.includes('use-icon-')) {
                    jQuery(elt).addClass('is-loaded');
                    let svg = item.replace('use-', '');
                    elt.prepend('<svg class="sprite-icon sprite-' + svg + '"><use xlink:href="#' + svg + '"></use></svg>');
                }

                if (item.includes('use-pattern-')) {
                    jQuery(elt).addClass('is-loaded');
                    let svg = item.replace('use-', '');
                    elt.prepend('<svg class="sprite-pattern sprite-' + svg + '"><use xlink:href="#' + svg + '"></use></svg>');
                }
            });
        });
    }
}
